import React from 'react'
import back from '../images/icon-back.svg'
import { Link } from 'react-router-dom'

function TopBar(props) {
  return (
    <>
      <Link to={`/`} className="top-bar-wrapper">
        <div>
          <img
            className="trg"
            src={back}
            style={{
              width: '48px',
              height: '48px',
            }}
          ></img>
        </div>
      </Link>
    </>
  )
}

export default TopBar
