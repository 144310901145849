import React, { useLayoutEffect, useState, useEffect } from 'react'

import MediaCarousel from './MediaCarousel'

import FunkyLanding from './FunkyLanding'
import PhotoPage_1up from './PhotoPage_1up'
import PhotoPage_2up from './PhotoPage_2up'
import PhotoPage_3up from './PhotoPage_3up'
import TwoCol from './TwoCol'
import Caption from './Caption'

import blanket from '../images/blanket.jpg'
import bin1 from '../images/burnt-bin-1.jpg'
import bin2 from '../images/burnt-bin-2.jpg'
import bin3 from '../images/burnt-bin-3.jpg'
import comforter from '../images/comforter.jpg'
import crystals from '../images/crystals.jpg'
import dollHair from '../images/doll-hair.jpg'
import frenchRoll from '../images/french-roll.jpg'
import fungus from '../images/fungus.jpg'
import rhubarb from '../images/giant-rhubarb.jpg'
import iceAsh from '../images/ice-and-ash.jpg'
import lichenBranches from '../images/lichen-on-branches.jpg'
import lichenRock from '../images/lichen-on-rock.jpg'
import rockFace from '../images/rock-face.jpg'
import carpet from '../images/scroched-carpet.jpg'
import bird from '../images/sea-bird.jpg'
import moss from '../images/spanish-moss.jpg'
import pavement from '../images/stained-pavement.jpg'
import stuffedAnimals from '../images/stuffed-animals.jpg'
import bear from '../images/stuffed-bear.jpg'
import xera from '../images/xera-plant.jpg'

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

export default function FunkyB() {
  const [isCompact, setIsCompact] = useState({
    twoL: null,
    twoP: null,
    threeP: null,
  })
  const [captionMargin, setCaptionMargin] = useState(null)
  const breakPoints = {
    twoL: getParam('break-point-2L'),
    twoP: getParam('break-point-2P'),
    threeP: getParam('break-point-3P'),
  }
  useEffect(() => {
    setIsCompact({
      twoL: window.innerWidth < breakPoints.twoL ? true : false,
      twoP: window.innerWidth < breakPoints.twoP ? true : false,
      threeP: window.innerWidth < breakPoints.threeP ? true : false,
    })
    setCaptionMargin(
      window.innerWidth <
        getParam('app-max-width') + getParam('padding-left-caption')
        ? getParam('padding-left-caption')
        : '0px'
    )
  }, [])

  useLayoutEffect(() => {
    function setLayout() {
      setIsCompact({
        twoL: window.innerWidth < breakPoints.twoL ? true : false,
        twoP: window.innerWidth < breakPoints.twoP ? true : false,
        threeP: window.innerWidth < breakPoints.threeP ? true : false,
      })
      setCaptionMargin(
        window.innerWidth <
          getParam('app-max-width') + getParam('padding-left-caption')
          ? getParam('padding-left-caption')
          : '0px'
      )
    }
    window.addEventListener('resize', setLayout)
    return () => window.removeEventListener('resize', setLayout)
  }, [])

  const carouselData = [
    <FunkyLanding />,
    <PhotoPage_1up
      props={{
        img1: bin1,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Burnt Recycling Bin No. 1, Noe Valley',
            }}
          />
        ),
      }}
    />,
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: bin2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Burnt Recycling Bin No. 2, Noe Valley',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: fungus,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Fungus, John McLaren Park',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: bin2,
          img2: fungus,
          caption: (
            <Caption
              props={{
                layout: 'caption_LR',
                leftMargin: captionMargin,
                title1: 'Burnt Recycling Bin No. 2, Noe Valley',
                title2: 'Fungus, John McLaren Park',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: lichenRock,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Lichen on Rock, Glen Canyon Park',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: bin3,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Burnt Recycling Bin No. 3, Noe Valley',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: lichenRock,
          img2: bin3,
          caption: (
            <Caption
              props={{
                layout: 'caption_LR',
                leftMargin: captionMargin,
                title1: 'Lichen on Rock, Glen Canyon Park',
                title2: 'Burnt Recycling Bin No. 3, Noe Valley',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: rhubarb,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Giant Rhubarb, San Francisco Botanical Garden',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: blanket,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Blanket, Dead Man’s Point',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: rhubarb,
          img2: blanket,
          caption: (
            <Caption
              props={{
                layout: 'caption_LR',
                leftMargin: captionMargin,
                title1: 'Giant Rhubarb, San Francisco Botanical Garden',
                title2: 'Blanket, Dead Man’s Point',
              }}
            />
          ),
        }}
      />
    ),
    <PhotoPage_2up
      props={{
        img1: comforter,
        img2: bird,
        caption: (
          <Caption
            props={{
              layout: `${isCompact.twoL ? 'caption_TB' : 'caption_LR'}`,
              leftMargin: captionMargin,
              title1: 'Comforter, Lands End',
              title2: 'Seabird, Baker Beach',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    isCompact.threeP ? (
      [
        <PhotoPage_1up
          props={{
            img1: frenchRoll,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'French Roll, North Beach gutter',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: pavement,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Stained Pavement, North Beach',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: rockFace,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Rock Face, Glen Canyon Park',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <PhotoPage_3up
        props={{
          img1: frenchRoll,
          img2: pavement,
          img3: rockFace,
          caption: (
            <Caption
              props={{
                layout: 'caption_LCR',
                leftMargin: captionMargin,
                title1: 'French Roll, North Beach gutter',
                title2: 'Stained Pavement, North Beach',
                title3: 'Rock Face, Glen Canyon Park',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: iceAsh,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Ice and Ash, North Beach',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: crystals,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Crystals, California Academy of Sciences',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: iceAsh,
          img2: crystals,
          caption: (
            <Caption
              props={{
                layout: 'caption_LR',
                leftMargin: captionMargin,
                title1: 'Ice and Ash, North Beach',
                title2: 'Crystals, California Academy of Sciences',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: stuffedAnimals,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Stuffed Animals, Fillmore',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: lichenBranches,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Lichen on Branches, Twin Peaks',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: stuffedAnimals,
          img2: lichenBranches,
          caption: (
            <Caption
              props={{
                layout: 'caption_LR',
                leftMargin: captionMargin,
                title1: 'Stuffed Animals, Fillmore',
                title2: 'Lichen on Branches, Twin Peaks',
              }}
            />
          ),
        }}
      />
    ),

    <PhotoPage_1up
      props={{
        img1: bear,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Stuffed Bear, Fillmore',
            }}
          />
        ),
      }}
    />,
    <PhotoPage_2up
      props={{
        img1: dollHair,
        img2: moss,
        caption: (
          <Caption
            props={{
              layout: `${isCompact.twoL ? 'caption_TB' : 'caption_LR'}`,
              leftMargin: captionMargin,
              title1: 'Doll Hair, Fillmore',
              title2: 'Spanish Moss, Golden Gate Park',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: carpet,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Scorched Carpet, Saint Marys Park',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: xera,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Xera Plant, Mission Terrace',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: carpet,
          img2: xera,
          caption: (
            <Caption
              props={{
                layout: 'caption_LR',
                leftMargin: captionMargin,
                title1: 'Scorched Carpet, Saint Marys Park',
                title2: 'Xera Plant, Mission Terrace',
              }}
            />
          ),
        }}
      />
    ),
  ]

  return (
    <>
      <MediaCarousel props={carouselData.flat()}></MediaCarousel>
    </>
  )
}
