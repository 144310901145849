import React, { useState, useLayoutEffect } from 'react'
import TopBar from './TopBar'
import youAreHerePoster from '../images/crowded-train-3600.jpg'

function mapRange(minInput, maxInput, minOutput, maxOutput, value) {
  const scaleFactor = (maxOutput - minOutput) / (maxInput - minInput)
  value = Math.max(minInput, Math.min(maxInput, value))
  return minOutput + (value - minInput) * scaleFactor
}

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

export default function YouAreHere(props) {
  const [bannerHeight, setBannerHeight] = useState(null)
  const [bodyWidth, setBodyWidth] = useState(null)
  useLayoutEffect(() => {
    function setHeight() {
      const height = mapRange(
        getParam('banner-height-min-input'),
        getParam('banner-height-max-input'),
        getParam('banner-height-min-output'),
        getParam('banner-height-max-output'),
        window.innerWidth
      )
      setBannerHeight(height)
      setBodyWidth(Math.min(window.innerWidth, getParam('app-max-width')))
    }
    window.addEventListener('resize', setHeight)
    setHeight()
    return () => window.removeEventListener('resize', setHeight)
  }, [])

  return (
    <>
      <div className="main-wrapper">
        <TopBar onClick={() => props.props.onClick('homepage')}></TopBar>
        <div className="body-wrapper">
          <div
            className="image"
            style={{
              backgroundImage: `url(${youAreHerePoster})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              width: '100%',
              height: `${bannerHeight}vh`,
              backgroundColor: 'lightgray',
            }}
          ></div>
          <div className="details-wrapper">
            <h1>
              You Are Here <span className="project-date">(ongoing)</span>
            </h1>
            <p>
              I sometimes get the feeling that <i>this</i> particular time and
              place are radiating a kind of evanescent grace – sometimes grand
              and obvious, other times humble or subtle. In May 2017, I began
              pointing my camera and letting it roll for 30 seconds or so,
              hoping the resulting vignette would communicate something of the
              affection I felt for the circumstances at that moment. I’ve been
              combining the best of these vignettes, playing on motifs of form
              and subject, sound and image in order to amplify their charm and
              the implicit invitation to connect that these moments offer.
              Ultimately, this work strives to coax a more profound appreciation
              for the existential splendor unfolding itself in our daily lives.
            </p>
            <div className="player-wrap">
              <iframe
                className="youtube-player"
                width="100%"
                height={`${(bodyWidth * 9) / 16}px`}
                src="https://www.youtube.com/embed/ME0qFc0fb6w?si=JKNClwr-1gKoz9mU"
                title="You Are Here"
                frameborder="0"
                controls="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
            <div className="player-wrap">
              <iframe
                className="youtube-player"
                width="100%"
                height={`${(bodyWidth * 9) / 16}px`}
                src="https://www.youtube.com/embed/UGzPs9s_pUs?si=5UV6yBny7I4u4rcO"
                title="You Are Here"
                frameborder="0"
                controls="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <div style={{ height: '28px' }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
