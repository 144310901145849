import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'

import SocialBar from './SocialBar'
import MediaThumbnail from './MediaThumbnail'
import HomepageHeader from './HomepageHeader'
import fondue from '../images/fondue-1200.jpg'
import babySock from '../images/baby-sock-1200.jpg'
import bear from '../images/stuffed-bear-1200.jpg'
import marryMe from '../images/marry-me-1200.jpg'

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

function Homepage() {
  const [isCompact, setIsCompact] = useState(null)
  const thumbnailLayoutBreakpoint = 932 // 300 (thumb min width) * 3 + 8 (padding) * 4

  useLayoutEffect(() => {
    function setLayout() {
      setIsCompact(
        // window.innerWidth < getParam('layout-breakpoint') ? true : false
        window.innerWidth < 932 ? true : false
      )
    }
    setLayout()
    window.addEventListener('resize', setLayout)
    return () => window.removeEventListener('resize', setLayout)
  }, [])

  const listContent = [
    <MediaThumbnail
      key="youAreHere"
      props={{
        headline: 'You Are Here',
        src: `${marryMe}`,
        path: '/youarehere',
      }}
    />,

    <MediaThumbnail
      key="lostAndFound"
      props={{
        headline: 'Lost & Found',
        src: `${babySock}`,
        path: '/lostandfound',
      }}
    />,
    <MediaThumbnail
      key="funkyBeautiful"
      props={{
        headline: 'Funky Beautiful',
        src: `${bear}`,
        path: '/funkybeautiful',
      }}
    />,
  ]

  if (isCompact == true) {
    listContent.splice(
      3,
      0,
      <div key="spacer" className="thumbnail-wrapper"></div>
    )
  }

  return (
    <>
      <div className="homepage-wrapper">
        <div>
          <HomepageHeader />
          <main>
            <h1>Featured work</h1>
            <div className="media-list-wrapper">{listContent}</div>
          </main>
        </div>
        <SocialBar />
      </div>
    </>
  )
}

export default Homepage
