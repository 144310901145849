function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

export default function ThreeUp(props) {
  return (
    <div className="photo-page-wrapper">
      <div
        className="imagesPlusCaption"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: `${getParam('gap-artworks')}px`,
          height: '100%',
        }}
      >
        <div
          className="image-row"
          style={{
            display: 'flex',
            gap: `${getParam('gap-artworks')}px`,
            flex: '1',
          }}
        >
          <div
            className="image"
            style={{
              backgroundImage: `url(${props.props.img1})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'right center',
              flex: '1',
            }}
          ></div>
          <div
            className="image"
            style={{
              backgroundImage: `url(${props.props.img2})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center center',
              flex: '1',
            }}
          ></div>
          <div
            className="image"
            style={{
              backgroundImage: `url(${props.props.img3})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'left center',
              flex: '1',
            }}
          ></div>
        </div>
        {props.props.caption}
      </div>
    </div>
  )
}
