import React from "react";
import monogram from "../images/eh-monogram.svg";

function HomepageHeader(props) {
  return (
    <>
      <div className="homepage-header">
        <img
          src={monogram}
          style={{
            width: "64px",
            height: "64px",
          }}
        ></img>
        <div className="eh-logotype-wrapper">
          <span className="eh-title">Eric Henry</span>
          <span className="eh-subtitle">
            Filmmaker, visual artist, designer
          </span>
        </div>
      </div>
    </>
  );
}

export default HomepageHeader;
