import React, { useEffect, useState, useRef } from 'react'
import {
  HashRouter,
  BrowserRouter,
  Routes,
  Route,
  Link,
} from 'react-router-dom'
import './App.css'

import YouAreHere from './components/YouAreHere'
import FunkyB from './components/FunkyB'
import Homepage from './components/Homepage'
import About from './components/About'
import LostAndFound from './components/LostAndFound'

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={<div className="app-wrapper">{<Homepage />}</div>}
          />
          <Route
            path="/lostandfound"
            element={<div className="app-wrapper">{<LostAndFound />}</div>}
          />
          <Route
            path="/youarehere"
            element={<div className="app-wrapper">{<YouAreHere />}</div>}
          />
          <Route
            path="/funkybeautiful"
            element={<div className="app-wrapper">{<FunkyB />}</div>}
          />
          <Route
            path="/about"
            element={<div className="app-wrapper">{<About />}</div>}
          />
        </Routes>
      </HashRouter>
    </>
  )
}

export default App
