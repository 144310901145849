import React, { useState, useLayoutEffect, useEffect } from 'react'
import sweater from '../images/sweater-3600.jpg'

function mapRange(minInput, maxInput, minOutput, maxOutput, value) {
  const scaleFactor = (maxOutput - minOutput) / (maxInput - minInput)
  value = Math.max(minInput, Math.min(maxInput, value))
  return minOutput + (value - minInput) * scaleFactor
}

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

function getBannerHeight() {
  const height = mapRange(
    getParam('banner-height-min-input'),
    getParam('banner-height-max-input'),
    getParam('banner-height-min-output'),
    getParam('banner-height-max-output'),
    window.innerWidth
  )
  return height
}

function getDetailsHeight() {
  const height =
    ((100 - getBannerHeight()) / 100) * window.innerHeight -
    getParam('footer-height')
  console.log(height)
  return height
}

export default function LostLanding() {
  const [bannerHeight, setBannerHeight] = useState(null)
  const [detailsHeight, setDetailsHeight] = useState(null)

  useEffect(() => {
    setBannerHeight(getBannerHeight())
    setDetailsHeight(getDetailsHeight())
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setBannerHeight(getBannerHeight())
      setDetailsHeight(getDetailsHeight())
    })
    return () =>
      window.removeEventListener('resize', () => {
        setBannerHeight(getBannerHeight())
        setDetailsHeight(getDetailsHeight())
      })
  }, [])

  return (
    <>
      <div className="main-wrapper">
        <div className="body-wrapper">
          <div
            className="image"
            style={{
              backgroundImage: `url(${sweater})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              width: '100%',
              height: `${bannerHeight}vh`,
              backgroundColor: 'lightgray',
            }}
          ></div>
          <div
            className="details-wrapper"
            style={
              {
                // height: `${detailsHeight}px`,
              }
            }
          >
            <h1>
              Lost & Found <span className="project-date">(2024)</span>
            </h1>
            <p>
              These photographs are of articles of lost clothing, captured first
              as I found them, and then again in my home after a process of
              reintegration. The process served as a meditation on restoring
              lost dignity, as I considered what kind of care each article
              needed, both literally and metaphorically. I carefully sterilized
              the garments (using a pressure cooker or by immersion in alcohol)
              and laundered them. Finally I took the second photograph, placing
              each article in the context I felt best showed due consideration:
              a coat rack for a scarf, a shelf for a sweater, my hands for an
              orphaned baby sock.
            </p>
            <p>
              Each of these objects is an intimate stand-in for a person who has
              suffered a loss, as casual as an accidentally dropped hat, or as
              profound as homelessness, mental illness, or the countless ways a
              person can be ostracized or mistreated. And attempts at
              restoration have fallen woefully short: holes and stains remain,
              garments remain forever absent from their owners. This work points
              to the enduring grief of these losses, as well as to the beauty
              that arises from making room for them in whatever ways we can.
              They are an integral part of who we are whether we choose to
              acknowledge them or not, whether we shun them, or begin to make
              them feel at home.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
