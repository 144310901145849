import React, { useState, useLayoutEffect } from 'react'
import TopBar from './TopBar'

import artistCV from '../images/eric-henry-artist-cv.pdf'
import designCV from '../images/eric-henry-design-cv.pdf'
import crystalRoom from '../images/crystal-room.jpg'

function mapRange(minInput, maxInput, minOutput, maxOutput, value) {
  const scaleFactor = (maxOutput - minOutput) / (maxInput - minInput)
  value = Math.max(minInput, Math.min(maxInput, value))
  return minOutput + (value - minInput) * scaleFactor
}

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

export default function About() {
  const [bannerHeight, setBannerHeight] = useState(null)
  useLayoutEffect(() => {
    function setHeight() {
      const height = mapRange(
        getParam('banner-height-min-input'),
        getParam('banner-height-max-input'),
        getParam('banner-height-min-output'),
        getParam('banner-height-max-output'),
        window.innerWidth
      )
      setBannerHeight(height)
    }
    window.addEventListener('resize', setHeight)
    setHeight()
    return () => window.removeEventListener('resize', setHeight)
  }, [])

  return (
    <>
      <div className="main-wrapper">
        <TopBar />
        <div className="body-wrapper">
          <div
            className="image"
            style={{
              backgroundImage: `url(${crystalRoom})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              width: '100%',
              height: `${bannerHeight}vh`,
              backgroundColor: 'lightgray',
            }}
          ></div>
          <div className="details-wrapper">
            <h1>About me</h1>
            <p>
              Eric Henry is a self-taught filmmaker, photographer, and designer.
              He approaches his subjects with playfulness and a keen eye for
              finding beauty, often where it is easily overlooked. He has a B.A.
              in English literature from Oberlin College in Ohio. His work has
              been exhibited at prestigious venues and events across the U.S.,
              Europe, and Asia, and features in the permanent collection of the
              San Francisco Museum of Modern Art. Henry has made San Francisco
              his home since 1994.
            </p>
            <h1>Statement</h1>
            <p>
              My recent work uses photography and video montage to examine the
              value of the things we discard or overlook, in our communities and
              within ourselves. I collect scenes from everyday life, and sift
              the charmless, broken, and abandoned objects I encounter in my
              wanderings for narratives of loss and unexpected beauty.
            </p>
            <p>
              My current practice straddles photography and filmmaking. My work
              is marked by sensibilities I developed as a filmmaker at an
              earlier stage of my career: a love of narrative storytelling, an
              emphasis on juxtaposition and temporal sequencing. My work also
              rests on cornerstone principles of photography, such as attention
              to form and framing, and the expressive potential of light. Even
              when shooting video, I’ve adopted a style inspired by still
              photography, abandoning camera movement to become a witness to the
              action within the frame.
            </p>
            <p>
              My work points to the ubiquity of rejection and loss alongside our
              capacity to make space for these experiences in all of their
              complexity. As a person living with a major mental illness, these
              themes are deeply personal. Ultimately, I aspire to use my work as
              an invitation to embrace this existence, at once exquisite and
              deeply-flawed.
            </p>
            <h1>Contact</h1>
            <p>eric@erichenry.com</p>
            <h1>Links</h1>
            <p
              className="trg"
              onClick={() => {
                window.open(artistCV)
              }}
            >
              <span className="text-link">Artist CV</span>
            </p>
            {/* <p
              className="trg"
              onClick={() => {
                window.open(designCV)
              }}
            >
              <span className="text-link">Design CV</span>
            </p> */}
            <div style={{ height: '28px' }}></div>
          </div>
        </div>
      </div>
    </>
  )
}
