import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'

import MediaCarousel from './MediaCarousel'

import LostLanding from './LostLanding'
import PhotoPage_1up from './PhotoPage_1up'
import PhotoPage_2up from './PhotoPage_2up'
import PhotoPage_3up from './PhotoPage_3up'
import TwoCol from './TwoCol'
import Caption from './Caption'

import armaniscarf1 from '../images/armani-scarf-1.jpg' // Lake Street
import armaniscarf2 from '../images/armani-scarf-2.jpg'
import babysock1 from '../images/baby-sock-1.jpg' // Glen Canyon Park
import babysock2 from '../images/baby-sock-2.jpg'
import blackdress1 from '../images/black-dress-1.jpg' // Oak Street
import blackdress2 from '../images/black-dress-2.jpg'
import bra1 from '../images/bra-1.jpg' // Geart Boulevard
import bra2 from '../images/bra-2.jpg'
import coverupdress1 from '../images/coverup-dress-1.jpg' // Balboa Street
import coverupdress2 from '../images/coverup-dress-2.jpg'
import deelcap1 from '../images/deel-cap-1.jpg' // Fell Street
import deelcap2 from '../images/deel-cap-2.jpg'
import floppyhat1 from '../images/floppy-hat-1.jpg' // The Embarcadero
import floppyhat2 from '../images/floppy-hat-2.jpg'
import gloves1 from '../images/gloves-1.jpg' // Clayton Street
import gloves2 from '../images/gloves-2.jpg'
import gauzyscarf1 from '../images/gauzy-scarf-1.jpg' // Dolores Street
import gauzyscarf2 from '../images/gauzy-scarf-2.jpg'
import jacketandshirt1 from '../images/jacket-and-shirt-1.jpg' // 3rd Avenue
import jacketandshirt2 from '../images/jacket-and-shirt-2.jpg'
import jacketandshirt3 from '../images/jacket-and-shirt-3.jpg'
import looneytoonst1 from '../images/looney-toons-t-1.jpg' // Cesar Chavez Street
import looneytoonst2 from '../images/looney-toons-t-2.jpg'
import oldnavysweater1 from '../images/old-navy-sweater-1.jpg' // Hickory Street
import oldnavysweater2 from '../images/old-navy-sweater-2.jpg'
import purse1 from '../images/purse-1.jpg' // Grove Street
import purse2 from '../images/purse-2.jpg'
import rlsweater1 from '../images/rl-sweater-1.jpg' // Billy Goat Hill
import rlsweater2 from '../images/rl-sweater-2.jpg'
import romper1 from '../images/romper-1.jpg' // Nob Hill
import romper2 from '../images/romper-2.jpg'
import slacks1 from '../images/slacks-1.jpg' // Steiner Street
import slacks2 from '../images/slacks-2.jpg'
import sneakers1 from '../images/sneakers-1.jpg' // San Jose Avenue
import sneakers2 from '../images/sneakers-2.jpg'
import tights1 from '../images/tights-1.jpg' // Octavia Boulevard
import tights2 from '../images/tights-2.jpg'
import woolscarf1 from '../images/wool-scarf-1.jpg' // Grove Street
import woolscarf2 from '../images/wool-scarf-2.jpg'
import yogat1 from '../images/yoga-t-1.jpg' // Church Street
import yogat2 from '../images/yoga-t-2.jpg'
import zarat1 from '../images/zara-t-1.jpg' // Alemany Boulevard
import zarat2 from '../images/zara-t-2.jpg'

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

export default function LostAndFound() {
  const [isCompact, setIsCompact] = useState({
    twoL: null,
    twoP: null,
    threeP: null,
  })
  const [captionMargin, setCaptionMargin] = useState(null)
  const breakPoints = {
    twoL: getParam('break-point-2L'),
    twoP: getParam('break-point-2P'),
    threeP: getParam('break-point-3P'),
  }
  useEffect(() => {
    setIsCompact({
      twoL: window.innerWidth < breakPoints.twoL ? true : false,
      twoP: window.innerWidth < breakPoints.twoP ? true : false,
      threeP: window.innerWidth < breakPoints.threeP ? true : false,
    })
    setCaptionMargin(
      window.innerWidth <
        getParam('app-max-width') + getParam('padding-left-caption')
        ? getParam('padding-left-caption')
        : '0px'
    )
  }, [])

  useLayoutEffect(() => {
    function setLayout() {
      setIsCompact({
        twoL: window.innerWidth < breakPoints.twoL ? true : false,
        twoP: window.innerWidth < breakPoints.twoP ? true : false,
        threeP: window.innerWidth < breakPoints.threeP ? true : false,
      })
      setCaptionMargin(
        window.innerWidth <
          getParam('app-max-width') + getParam('padding-left-caption')
          ? getParam('padding-left-caption')
          : '0px'
      )
    }
    window.addEventListener('resize', setLayout)
    return () => window.removeEventListener('resize', setLayout)
  }, [])

  const carouselData = [
    <LostLanding />,
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: romper1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Romper, Nob Hill (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: romper2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Romper, Nob Hill (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: romper1,
          img2: romper2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Romper, Nob Hill',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: slacks1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Trousers, Steiner Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: slacks2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Trousers, Steiner Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: slacks1,
          img2: slacks2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Trousers, Steiner Street',
              }}
            />
          ),
        }}
      />
    ),
    <PhotoPage_2up
      props={{
        img1: rlsweater1,
        img2: rlsweater2,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Cable Knit Sweater, Billy Goat Hill',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    <PhotoPage_2up
      props={{
        img1: sneakers1,
        img2: sneakers2,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Sneakers, San Jose Avenue',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: woolscarf1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Wool Scarf, Grove Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: woolscarf2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Wool Scarf, Grove Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: woolscarf1,
          img2: woolscarf2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Wool Scarf, Grove Street',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: zarat1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'V-Neck T-Shirt No. 1, Alemany Boulevard (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: zarat2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'V-Neck T-Shirt No. 1, Alemany Boulevard (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: zarat1,
          img2: zarat2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'V-Neck T-Shirt No. 1, Alemany Boulevard',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: babysock1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Baby Sock, Glen Park Canyon (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: babysock2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Baby Sock, Glen Park Canyon (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: babysock1,
          img2: babysock2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Baby Sock, Glen Park Canyon',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.threeP ? (
      [
        <PhotoPage_1up
          props={{
            img1: jacketandshirt2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Men’s Shirt & Coat, 3rd Avenue (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: jacketandshirt1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Men’s Shirt, 3rd Avenue (after)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: jacketandshirt3,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Men’s Coat, 3rd Avenue (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <PhotoPage_3up
        props={{
          img1: jacketandshirt1,
          img2: jacketandshirt2,
          img3: jacketandshirt3,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Men’s Shirt & Coat, 3rd Avenue',
              }}
            />
          ),
        }}
      />
    ),

    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: gauzyscarf1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Muslin Scarf, Dolores Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: gauzyscarf2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Muslin Scarf, Dolores Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: gauzyscarf1,
          img2: gauzyscarf2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Muslin Scarf, Dolores Street',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: blackdress1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Mini Dress, Oak Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: blackdress2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Mini Dress, Oak Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: blackdress1,
          img2: blackdress2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Mini Dress, Oak Street',
              }}
            />
          ),
        }}
      />
    ),
    <PhotoPage_2up
      props={{
        img1: bra1,
        img2: bra2,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Bra, Geary Boulevard',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    <PhotoPage_2up
      props={{
        img1: purse1,
        img2: purse2,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Velvet Purse, Grove Street',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: armaniscarf1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Check Scarf, Lake Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: armaniscarf2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Check Scarf, Lake Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: armaniscarf1,
          img2: armaniscarf2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Check Scarf, Lake Street',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: oldnavysweater1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Boatneck Sweater, Hickory Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: oldnavysweater2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Boatneck Sweater, Hickory Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: oldnavysweater1,
          img2: oldnavysweater2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Boatneck Sweater, Hickory Street',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: looneytoonst1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Looney Toons T-Shirt, Cesar Chavez Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: looneytoonst2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Looney Toons T-Shirt, Cesar Chavez Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: looneytoonst1,
          img2: looneytoonst2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Looney Toons T-Shirt, Cesar Chavez Street',
              }}
            />
          ),
        }}
      />
    ),
    <PhotoPage_2up
      props={{
        img1: floppyhat1,
        img2: floppyhat2,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Floppy Hat, The Embarcadero',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    <PhotoPage_2up
      props={{
        img1: gloves1,
        img2: gloves2,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Leather Gloves, Clayton Street',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: coverupdress1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Cover Up Dress, Balboa Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: coverupdress2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Cover Up Dress, Balboa Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: coverupdress1,
          img2: coverupdress2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Cover Up Dress, Balboa Street',
              }}
            />
          ),
        }}
      />
    ),
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: yogat1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'V-Neck T-Shirt No. 2, Church Street (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: yogat2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'V-Neck T-Shirt No. 2, Church Street (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: yogat1,
          img2: yogat2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'V-Neck T-Shirt No. 2, Church Street',
              }}
            />
          ),
        }}
      />
    ),
    <PhotoPage_2up
      props={{
        img1: deelcap1,
        img2: deelcap2,
        caption: (
          <Caption
            props={{
              layout: 'caption_single',
              leftMargin: captionMargin,
              title1: 'Baseball Cap, Fell Street',
            }}
          />
        ),
        isCompact: isCompact.twoL,
      }}
    />,
    isCompact.twoP ? (
      [
        <PhotoPage_1up
          props={{
            img1: tights1,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Leggings, Octavia Boulevard (before)',
                }}
              />
            ),
          }}
        />,
        <PhotoPage_1up
          props={{
            img1: tights2,
            caption: (
              <Caption
                props={{
                  layout: 'caption_single',
                  leftMargin: captionMargin,
                  title1: 'Leggings, Octavia Boulevard (after)',
                }}
              />
            ),
          }}
        />,
      ]
    ) : (
      <TwoCol
        props={{
          img1: tights1,
          img2: tights2,
          caption: (
            <Caption
              props={{
                layout: 'caption_single',
                leftMargin: captionMargin,
                title1: 'Leggings, Octavia Boulevard',
              }}
            />
          ),
        }}
      />
    ),
  ]

  return (
    <>
      <MediaCarousel props={carouselData.flat()}></MediaCarousel>
    </>
  )
}
