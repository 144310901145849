function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

export default function OneCol(props) {
  return (
    <div className="photo-page-wrapper">
      <div
        className="imagesPlusCaption"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: `${getParam('gap-artworks')}px`,
          height: '100%',
        }}
      >
        <div
          className="image"
          style={{
            backgroundImage: `url(${props.props.img1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center bottom',
            flex: '1',
          }}
        ></div>
        <div
          className="image"
          style={{
            backgroundImage: `url(${props.props.img2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center top',
            flex: '1',
          }}
        ></div>
        {props.props.caption}
      </div>
    </div>
  )
}
