function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

export default function OneCol(props) {
  return (
    <div className="photo-page-wrapper">
      <div className="imagesPlusCaption">
        <div
          className="image"
          style={{
            backgroundImage: `url(${props.props.img1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            flex: '1',
          }}
        ></div>
        {props.props.caption}
      </div>
    </div>
  )
}
