import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import OneCol from './OneCol.jsx'
import TwoCol from './TwoCol.jsx'

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

function PhotoPage_2up(props) {
  const [isOneCol, setIsOneCol] = useState(null)

  useLayoutEffect(() => {
    function setLayout() {
      setIsOneCol(
        window.innerWidth < getParam('layout-breakpoint') ? true : false
      )
    }
    setLayout()
    window.addEventListener('resize', setLayout)
    return () => window.removeEventListener('resize', setLayout)
  }, [])

  return props.props.isCompact === true ? (
    <OneCol props={props.props} />
  ) : (
    <TwoCol props={props.props} />
  )
}

export default PhotoPage_2up
