import React, { useState, useLayoutEffect, useEffect } from 'react'
import stuffedAnimals from '../images/stuffed-animals-3600.jpg'

function mapRange(minInput, maxInput, minOutput, maxOutput, value) {
  const scaleFactor = (maxOutput - minOutput) / (maxInput - minInput)
  value = Math.max(minInput, Math.min(maxInput, value))
  return minOutput + (value - minInput) * scaleFactor
}

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

function getBannerHeight() {
  const height = mapRange(
    getParam('banner-height-min-input'),
    getParam('banner-height-max-input'),
    getParam('banner-height-min-output'),
    getParam('banner-height-max-output'),
    window.innerWidth
  )
  return height
}

function getDetailsHeight() {
  const height =
    ((100 - getBannerHeight()) / 100) * window.innerHeight -
    getParam('footer-height')
  console.log(height)
  return height
}

export default function FunkyLanding() {
  const [bannerHeight, setBannerHeight] = useState(null)
  const [detailsHeight, setDetailsHeight] = useState(null)

  useEffect(() => {
    setBannerHeight(getBannerHeight())
    setDetailsHeight(getDetailsHeight())
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setBannerHeight(getBannerHeight())
      setDetailsHeight(getDetailsHeight())
    })
    return () =>
      window.removeEventListener('resize', () => {
        setBannerHeight(getBannerHeight())
        setDetailsHeight(getDetailsHeight())
      })
  }, [])

  return (
    <>
      <div className="main-wrapper">
        <div className="body-wrapper">
          <div
            className="image"
            style={{
              backgroundImage: `url(${stuffedAnimals})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              width: '100%',
              height: `${bannerHeight}vh`,
              backgroundColor: 'lightgray',
            }}
          ></div>
          <div
            className="details-wrapper"
            style={
              {
                // height: `${detailsHeight}px`,
              }
            }
          >
            <h1>
              Funky Beautiful <span className="project-date">(2023)</span>
            </h1>
            <p>
              Funky Beautiful is a series of found object photographs taken in
              San Francisco between August and November 2023. I use black and
              white processing and playful, sometimes poignant juxtaposition to
              reveal affinities between natural forms and the charmless, broken,
              and abandoned objects I encounter in my wanderings. Rendered as
              pure highlight and shadow, contour and texture, these subjects
              both lovely and unloved emerge as equally compelling, and invite
              us to extend the range of what we consider to be worthy of our
              attention.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
