export default function caption(props) {
  const captionGap = '8px'
  const captionLayouts = {
    caption_single: (
      <div className="caption" style={{ paddingLeft: props.props.leftMargin }}>
        <span>{props.props.title1}</span>
      </div>
    ),
    caption_LR: (
      <div className="caption" style={{ paddingLeft: props.props.leftMargin }}>
        <span>{props.props.title1}</span>
        <span
          style={{ fontStyle: 'italic', marginRight: captionGap }}
        >{` (L),`}</span>
        <span>{props.props.title2}</span>
        <span style={{ fontStyle: 'italic' }}>{` (R)`}</span>
      </div>
    ),
    caption_TB: (
      <div className="caption" style={{ paddingLeft: props.props.leftMargin }}>
        <span>{props.props.title1}</span>
        <span
          style={{ fontStyle: 'italic', marginRight: captionGap }}
        >{` (T),`}</span>
        <span>{props.props.title2}</span>
        <span style={{ fontStyle: 'italic' }}>{` (B)`}</span>
      </div>
    ),
    caption_LCR: (
      <div className="caption" style={{ paddingLeft: props.props.leftMargin }}>
        <span>{props.props.title1}</span>
        <span
          style={{ fontStyle: 'italic', marginRight: captionGap }}
        >{` (L),`}</span>
        <span>{props.props.title2}</span>
        <span
          style={{ fontStyle: 'italic', marginRight: captionGap }}
        >{` (C),`}</span>
        <span>{props.props.title3}</span>
        <span style={{ fontStyle: 'italic' }}>{` (R)`}</span>
      </div>
    ),
  }
  return captionLayouts[props.props.layout]
}
