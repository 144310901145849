import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

function MediaThumbnail(props) {
  return (
    <>
      <Link to={props.props.path} className="thumbnail-wrapper trg">
        <div className="thumbnail-img-wrapper">
          <img className="poster-img" src={props.props.src} />
        </div>
        <h3>{props.props.headline}</h3>
      </Link>
    </>
  )
}

export default MediaThumbnail
