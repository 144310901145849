import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import {
  A11y,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/keyboard'
import 'swiper/css/mousewheel'
import '../css/MySwiper.css'
import '../css/navigationCustom.css'

// import '../css/paginationCustom.css'

import TopBar from './TopBar'

function getParam(varName) {
  const paramValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--${varName}`)
  return parseInt(paramValue.replace('px', ''))
}

function MediaCarousel(props) {
  const swiperParameters = {
    modules: [A11y, Keyboard, Mousewheel, Navigation, Pagination],
    mousewheel: { enabled: true },
    observer: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    observeParents: true,
    watchSlidesProgress: true,
    grabCursor: true,
    pagination: { clickable: true, type: 'fraction', el: '.swiper-pagination' },
    lazy: { enabled: true },
    keyboard: { enabled: true },
    spaceBetween: 0,
  }

  const [isCompact, setIsCompact] = useState(null)

  useLayoutEffect(() => {
    function setLayout() {
      setIsCompact(
        window.innerWidth < getParam('layout-breakpoint') ? true : false
      )
    }
    setLayout()
    window.addEventListener('resize', setLayout)
    return () => window.removeEventListener('resize', setLayout)
  }, [])

  return (
    <>
      <div className="main-wrapper">
        <TopBar onClick={() => props.onClick('homepage')}></TopBar>
        <Swiper {...swiperParameters}>
          {props.props.map((item, index) => (
            <SwiperSlide key={index}>{item}</SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination" />
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </div>
    </>
  )
}
export default MediaCarousel
