import React from 'react'
import { Link } from 'react-router-dom'
import youtube from '../images/social-youtube.svg'
import linkedin from '../images/social-linkedin.svg'
import instagram from '../images/social-instagram.svg'
import email from '../images/social-email.svg'

function SocialBar() {
  return (
    <>
      <div className="social-bar-wrapper">
        <div className="social-bar-content">
          <div className="about-link-wrapper trg">
            <Link to={`/about`} className="about-link text-link">
              <h3 className="about-link text-link">About</h3>
            </Link>
          </div>
          <div id="spacer" style={{ height: '48px' }}></div>
          <a href="https://www.youtube.com/@erichenry" target="_blank">
            <img
              src={youtube}
              style={{
                width: '40px',
                height: '40px',
              }}
            ></img>
          </a>
          <a
            href="https://www.instagram.com/erichenrymovingpictures/"
            target="_blank"
          >
            <img
              src={instagram}
              style={{
                width: '40px',
                height: '40px',
              }}
            ></img>
          </a>
          <a href="https://www.linkedin.com/in/-eric-henry" target="_blank">
            <img
              src={linkedin}
              style={{
                width: '40px',
                height: '40px',
              }}
            ></img>
          </a>
          <img
            className="trg"
            src={email}
            onClick={() =>
              alert(
                'Well hello there! Please drop me a line at eric@erichenry.com'
              )
            }
            style={{
              width: '40px',
              height: '40px',
            }}
          ></img>
        </div>
      </div>
    </>
  )
}

export default SocialBar
